* {
    margin: 0;
    padding: 0;
}

html,
body {
    /*  */
}

html {
    background-color: black;
}

.fullbody {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
    min-height: 500px;
    /* scroll-snap-type: y mandatory;
scroll-snap-points-y: repeat(100px);
scroll-snap-type: y mandatory; */
    scroll-snap-type: y proximity;
    scroll-behavior: smooth;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -2;
    background-color: #000000;
}

@font-face {
    font-family: aloneInSpace;
    src: url('../static/font/AloneInSpace.ttf');
}

@font-face {
    font-family: akkuratPro;
    src: url('../static/font/akkurat-Light.ttf');
}

#loading {
    background: black;
    display: flex;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    /* background-color: transparent;
    background-image: url("https://cdn.dribbble.com/users/563824/screenshots/4155980/untitled-11.gif");
    background-repeat: no-repeat;
    background-size: cover; */
    background-position: center;
}

#loading>svg {
    font-family: 'aloneInSpace', sans-serif;
    position: absolute;
    width: 100%;
    height: 100%;
}

#loading>svg>text {
    text-transform: uppercase;
    animation: stroke 5s reverse;
    stroke-width: 1;
    stroke: #ff1f78;
    font-size: 6.0em;
    font-weight: bold;
}

#loading>svg:hover {
    transform: none;
}

@keyframes stroke {
    0% {
        fill: #fff;
        stroke: #ff1f78;
        stroke-dashoffset: 25%;
        stroke-dasharray: 0 50%;
        stroke-width: 1;
    }
    70% {
        fill: #fff;
        stroke: #ff1f78;
    }
    80% {
        fill: #fff;
        stroke: #00ccff;
        stroke-width: 2;
    }
    100% {
        fill: #fff;
        stroke: #00ccff;
        stroke-dashoffset: -25%;
        stroke-dasharray: 50% 0;
        stroke-width: 0;
    }
}

@import url('./Css/section1/section1.scss');
@import url('./Css/section2/section2.scss');
@import url('./Css/section3/section3.scss');
@import url('./Css/section4/section4.scss');
@import url('./Css/section5/section5.scss');
@import url('./Css/section6/section6.scss');
@import url('./Css/section7/section7.scss');
@import url('./Css/legacy/legacy.scss');