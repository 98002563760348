.section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    height: 230vh;
    font-size: 6vmin;
    padding-top: 4%;
    padding-left: 10%;
    padding-right: 10%;
    min-height: 100vh;
    /*scroll-snap-align: start;*/
    /* background-color: blue;   */
}

.title-Mission {
    font-family: 'aloneInSpace', sans-serif;
}

.averageTime {
    /* background-color:#2A3; */
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    height: 40vh;
    width: 40%;
    position: absolute;
    right: 0;
    bottom: 64vh;
    font-size: 3vmin;
    font-family: Montserrat;
}

.averageTimeText:first-letter {
    text-transform: uppercase;
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* background-color: blue;  */
}

.paraOuMission {
    font-family: akkuratPro;
    background: linear-gradient( to bottom, rgb(255, 255, 255) 50%, rgb(53, 53, 53) 50%);
    background-size: 100% 200%;
    background-position-y: 0%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 6vmin;
}

.paraOuMission::first-letter {
    text-transform: uppercase;
}

.descriptionMission {
    width: 55%;
    margin-top: 15%;
    /* background-color:#2A3; */
}

.ourPartner {
    position: absolute;
    bottom: 40vh;
}

.productPicture {
    position: absolute;
    top: 40vh;
    left: 0;
    /* background-color: #2A3; */
}

.partner {
    font-size: 6vmin;
    font-family: aloneInSpace;
}

.product {
    object-fit: contain;
    width: 100%;
    margin: 0;
    /* background-color:#2AB */
}

.textContainer {
    position: absolute;
    overflow: hidden;
    width: 100%;
    display: flex;
    bottom: 10vh;
    right: 0;
    z-index: 0;
    font-size: 10vh;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    /* background-color:#2AB; */
}

.txt {
    white-space: nowrap;
    animation: scrollSliding 100s linear infinite;
}


/* 
.tl1{
    color:#2A3
}

.tl2{
    color: #2AB;
} */

@keyframes scrollSliding {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0)
    }
}

.textContainer2 {
    position: absolute;
    display: flex;
    overflow: hidden;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 0;
    font-size: 10vh;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
}

.slidingSeconde {
    white-space: nowrap;
    animation: scrollSlidingRight 100s linear infinite;
}

@keyframes scrollSlidingRight {
    0% {
        transform: translate(-100%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

.explainerText {
    font-family: akkuratPro;
    font-size: 0.3em;
}

.glassmorphisme {
    margin: 0.3em;
    padding: 0.2em;
    background: rgba(81, 81, 81, 0.25);
    backdrop-filter: blur( 10.5px);
    -webkit-backdrop-filter: blur( 10.5px);
    border-radius: 10px;
}

.canvasLogo {
    position: absolute;
    top: 35vh;
    left: 4em;
    /* background-color:red; */
}

.textSablier {
    display: flex;
    flex-direction: column;
    font-family: akkuratPro;
}

.numberEleven {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.numberCounter {
    margin: 0 5px;
}

.eleven {
    font-size: 4em;
}

.sablier {
    height: 30vh;
    margin-left: -10%;
}

@media (max-width: 768px) {
    .section2 {
        padding-top: 5%;
        padding-left: 0%;
        padding-right: 0%;
        min-height: 100vh;
        height: auto;
        scroll-snap-align: none;
        /*background-color: blue; */
    }
    .ourPartner {
        position: relative;
        top: 0;
    }
    .partner {
        font-size: 7vmin;
        text-align: center;
    }
    .descriptionMission {
        position: relative;
        /*background-color:#2A3; */
        padding-left: 5%;
        padding-right: 5%;
        width: auto;
    }
    .paraOuMission {
        width: auto;
        margin-top: 5vh;
    }
    .productPicture {
        position: relative;
        top: 0vh;
        left: 0vw;
    }
    .averageTime {
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 100vw;
        position: relative;
        top: -6vh;
        font-size: 3vmin;
        font-family: Montserrat;
    }
    .textContainer {
        font-size: 8vh;
        font-family: Montserrat, sans-serif;
        font-weight: 800;
        /* background-color:#2AB; */
    }
    .sablier {
        height: 18vh;
        margin: 0vw 0vw 0vw 3vw;
    }
    .textContainer2 {
        font-size: 8vh;
        font-family: Montserrat, sans-serif;
        font-weight: 800;
        /* background-color:#2AB; */
    }
    .title-Mission {
        font-size: 8vmin;
    }
    .textContainer {
        position: relative;
        top: 1vh;
        font-size: 3vh;
    }
    .textContainer2 {
        position: relative;
        top: 0;
        font-size: 3vh;
    }
}