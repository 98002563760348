.overlay {
    position: fixed;
    display: none;
    height: 100%;
    z-index: 5;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37);
    backdrop-filter: blur( 17.5px);
    -webkit-backdrop-filter: blur( 17.5px);
    -webkit-appearance: none;
    border-radius: 10px;
    //mix-blend-mode: difference;
    //-webkit-mix-blend-mode: difference;
    animation: popUp 0.45s cubic-bezier(0.76, 0, 0.3, 1) alternate;
}

@keyframes popUp {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}

.cross {
    position: absolute;
    top: 4vh;
    right: 4vh;
    transform: scale(2)
}

.media:hover {
    cursor: pointer;
    transform: scale(2.5);
}

.cross:hover {
    cursor: pointer;
    transform: scale(2.5);
}

.littleText {
    font-family: akkuratPro;
    font-size: 1.6vmin;
    margin: 4vmin;
    filter: invert(100%);
    transition: 0.5s;
    text-transform: capitalize;
}

.littleText:hover {
    color: #007d37;
    transition: 0.5s;
}

.termRight {
    display: flex;
    flex-direction: row;
}

.socialMedia {
    display: flex;
    flex-direction: row;
}

.media {
    width: 2em;
    margin: 5vmin;
    transform: scale(2)
}

.info {
    position: absolute;
    top: 43vh;
    right: 20%;
    filter: invert(100%);
    font-family: akkuratPro;
    font-size: 1.9vmin;
}

.info p {
    text-transform: capitalize;
}

.info p a {
    text-decoration: none;
    color: #ff1f78!important;
    filter: invert(100%)!important;
    text-transform: lowercase;
}

.details {
    font-size: 4vmin;
    margin-bottom: 3vmin;
    font-weight: bolder;
}

.mail::first-letter {
    text-transform: capitalize;
}

.zatpayZLogo {
    position: absolute;
    top: 25vh;
    left: 20%;
    filter: invert(100%);
    height: 40vh;
}

.lang img {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.langue {
    margin-right: 1vw;
}

#troisBar {
    cursor: pointer;
}

@media (max-width: 1400px) {
    .zatpayZLogo {
        position: absolute;
        top: 20vh;
        width: 50%;
        left: 25%;
        filter: invert(100%);
        height: 20vh;
    }
    .info {
        position: absolute;
        top: 43vh;
        text-align: center;
        width: 100%;
        left: auto;
        right: auto;
        filter: invert(100%);
        font-family: akkuratPro;
        font-size: 3.9vmin;
    }
    .littleText {
        font-size: 2.6vmin;
    }
    .socialMedia {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 63vh;
        width: 100%;
    }
}