@import url('./cursor.scss');
@import url('./header.scss');
.section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    font-family: 'aloneInSpace', sans-serif;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 6vmin;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    min-height: 100vh;
    /*scroll-snap-align: start;*/
}

.section1 {
    flex-direction: column;
}

div.downlaod {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    margin-top: 0.2em;
}

.downlaodLink {
    display: flex;
    max-width: 2em;
    width: auto;
    max-height: 15px;
    flex-direction: row;
    padding: 0.3em;
    border-radius: 30px;
    align-items: center;
    margin: 0px 0.2em;
    background: rgba(26, 26, 26, 0.089);
    backdrop-filter: blur( 2px);
    -webkit-backdrop-filter: blur( 10px);
    transition: 0.2s cubic-bezier(.13, .66, .15, .86);
}

.downlaodLink:hover {
    transform: scale(1.1);
    transition: 0.2s cubic-bezier(.13, .66, .15, .86);
}

.googleStoreLogo,
.googleStoreLogo {
    margin: 0.01em 0.05em;
    height: 0.3em
}

.appstoreText {
    font-size: 1vmin;
    font-family: akkuratPro, sans-serif;
}

.appstoreText::first-letter {
    text-transform: uppercase;
}

.appstoreTextbold {
    font-size: 1.7vmin;
}

.mainTitle {
    font-family: aloneInSpace;
    padding-left: 3%;
    display: flex;
    padding-bottom: 0.0em;
}

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
    .mainTitle {
        text-shadow: 0px 0px 20px #ffffffb1;
    }
}

p {
    text-transform: lowercase;
    align-items: center;
}

div.mainTextDiv {
    width: 40vw;
    height: 10vh;
}

.mainText {
    font-family: akkuratPro, sans-serif;
    font-size: 2vmin;
    text-align: center;
    text-transform: lowercase;
}

.mainTextSCP {
    text-transform: uppercase;
    color: #00ccff;
}

.mainText:first-letter {
    text-transform: uppercase;
}

.doubleClickRemainds {
    text-align: center;
    position: relative;
    top: 23vh;
    font-size: 1vmin;
    animation: doubleClickMessage 2s cubic-bezier(0.76, 0, 0.3, 1) infinite alternate;
}

.doubleClickRemainds.disappear {
    display: none;
}

@keyframes doubleClickMessage {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(1.1)
    }
}

ul li {
    color: #fff;
}


/*
section:nth-child(odd)
{
    justify-content: flex-end;
}
*/

header {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.navigationInner {
    justify-content: space-between;
    display: flex;
    padding: 2em 2.5em;
    flex-direction: row;
    box-sizing: content-box;
    width: 100%;
    align-items: center;
}

ul {
    position: fixed;
    top: 40vh;
    left: 0;
    flex-direction: column;
    z-index: 2;
}

ul li {
    list-style: none;
    transition: 500ms cubic-bezier(.13, .66, .15, .86);
    margin: 37px 10px;
}

ul li a {
    font-size: 1.2em;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.2);
    margin: 10px 5px;
    transition: 500ms cubic-bezier(.13, .66, .15, .86);
    font-family: akkuratPro, sans-serif;
    font-weight: 300;
    cursor: none;
}

ul li:hover {
    transform: translate(10%, 0);
    transition: 500ms cubic-bezier(.13, .66, .15, .86);
}

ul li a:hover {
    color: #fff;
    font-size: 1.5em;
    transition: 500ms cubic-bezier(.13, .66, .15, .86);
}

.scrollView {
    position: absolute;
    bottom: -3vh;
    left: 49vw;
    transform: scale(0.3);
    animation: scrollSvgLoop 1.2s cubic-bezier(0.76, 0, 0.3, 1) infinite alternate;
}

.scrollView.disappear {
    opacity: 0;
    transition: 0.5s;
    animation: scrollSvgLoop 1.2s cubic-bezier(0.76, 0, 0.3, 1) infinite alternate;
}

@keyframes scrollSvgLoop {
    from {
        transform: scale(0.3);
    }
    to {
        transform: scale(0.35)
    }
}


/*

document.body.style.cursor = 'none';

@keyframes cursorAnim 
{
    from 
    {
        transform:scale(1);
     }
     to 
   {
       transform:scale(1.1)
   }
}*/

.lineCenter {
    position: absolute;
    left: 50%;
    width: 1px;
    background-color: #fff;
    align-self: center;
    z-index: 2;
}

.lineProgression {
    position: fixed;
    left: 18px;
    top: 44vh;
    width: 1px;
    background-color: #fff;
    align-self: center;
    z-index: 2;
}

@media (max-width: 700px) {
    .section {
        padding-left: 3%;
        padding-right: 3%;
        scroll-snap-align: none;
    }
    .scrollView {
        //bottom: 10vh;
    }
    .doubleClickRemainds,
    #navigationLeft {
        display: none;
    }
    .mainText {
        font-family: akkuratPro, sans-serif;
        font-size: 3.5vmin;
        text-align: center;
        text-transform: lowercase;
        margin: 1rem;
    }
    .googleStoreLogo {
        margin: 0.01em 0.05em;
        height: 1em
    }
    .appstoreText {
        font-size: 2.5vmin;
        font-family: akkuratPro, sans-serif;
    }
    .appstoreTextbold {
        font-size: 3.5vmin;
    }
    .downlaodLink {
        display: flex;
        max-width: 500em;
        width: auto;
        max-height: 15px;
        flex-direction: row;
        padding: 0.7em;
        border-radius: 30px;
        align-items: center;
        margin: 0.2em 0.2em;
        background: rgba(26, 26, 26, 0.089);
        backdrop-filter: blur( 2px);
        -webkit-backdrop-filter: blur( 10px);
        transition: 0.2s cubic-bezier(.13, .66, .15, .86);
    }
    .downlaodTwoLineText {
        margin-left: 0.5em;
        width: auto;
    }
    div.mainTextDiv {
        width: auto;
    }
    .navigationInner {
        padding: 2em 1.5em;
    }
}