.section5 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 95vh;
    position: relative;
    font-family: 'aloneInSpace', sans-serif;
    color: rgb(255, 255, 255);
    flex-direction: row;
    text-transform: uppercase;
    font-size: 6vmin;
    padding-right: 8%;
    /* scroll-snap-align: start; */
    background-color: #fff;
}

.titleDash {
    color: #000;
    font-size: 3vmin;
    margin-bottom: 1vh;
}

.rose {
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.white {
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #000;
}

.integrationDescriptionDiv {
    flex-direction: column;
    /* background-color: #1AB; */
}

.timeIntegration {
    margin: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.integrationDescription {
    white-space: nowrap;
    font-size: 2vmin;
    font-family: montserrat, sans-serif;
    text-transform: capitalize;
    /* background-color:#2A3; */
}

.integrationDescription:first-letter {
    text-transform: uppercase;
}

.inetgrationLine {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2vh 0vh;
    justify-content: flex-start;
}

.rfid {
    width: 4vh;
    margin-right: 3%;
}

.demo {
    position: absolute;
}

.milieu {
    z-index: 4;
    height: 40vh;
}

.haut-gauche,
.haut-droite,
.bas-gauche,
.bas-droite {
    height: 16vh;
}

@media (max-width: 768px) {
    .titleDash {
        color: #000;
        font-size: 5.5vmin;
        margin-bottom: 1vh;
        text-align: center;
    }
    .section5 {
        flex-direction: column;
        padding-right: 0%;
        height: 90vh;
    }
    .inetgrationLine {
        align-items: center;
    }
    .white {
        position: relative;
        margin-top: 20vh;
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #000;
    }
    .rose {
        position: relative;
        top: 0vh;
        width: 100%;
        height: 10%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
    .milieu {
        z-index: 4;
        height: 20vh;
    }
    .haut-gauche,
    .haut-droite,
    .bas-gauche,
    .bas-droite {
        height: 10vh;
    }
    .integrationDescription {
        font-size: 3vmin;
    }
}