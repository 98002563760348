header {
    position: fixed;
    z-index: 2;
}

img.logo {
    height: 2em;
}

svg {
    width: 2em;
    transition: 0.2s;
    cursor: none;
}

svg:hover {
    transform: scaleY(1.3);
    transform: rotate(180deg);
    transition: 0.6s cubic-bezier(.05, .67, 0, 1.53);
}

.iconify {
    width: 1em;
}

.lien a {
    color: #fff;
    font-size: 1.2em;
    transition: 500ms cubic-bezier(.13, .66, .15, .86);
}

.onTop {
    position: fixed;
    bottom: 2vh;
    right: 2vw;
    z-index: 2;
    background-color: #ff1f78!important;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 3.1em;
    transition: none!important;
    filter: none!important;
    transform: none!important;
}

.onTop .iconify {
    transform: none!important;
}