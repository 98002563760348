
.inner-cursor 
{
    position:fixed;
    left:28px;
    width : 14px;
    height:14px;
    transform: translate(-50%, -50%);
    background-color:rgb(255, 255, 255);
    mix-blend-mode:difference;
    border-radius: 50%;
    pointer-events:none;
    transition: width 0.2s, height 0.2s;
    z-index: 2;
}

.inner-cursor.grow
{
    width: 60px;
    height:60px;
    mix-blend-mode:difference;
    transition: width 0.2s cubic-bezier(.43,1.59,0,1.75), height 0.2s cubic-bezier(.43,1.59,0,1.75);
    z-index: 2;
}

.inner-cursor.grow2
{
    width: 100px;
    height:100px;
    mix-blend-mode:difference;
    transition: width 0.2s cubic-bezier(.43,1.59,0,1.75), height 0.2s cubic-bezier(.43,1.59,0,1.75);
    z-index: 2;
}

.outer-cursor 
{
    position:relative;
    width: 5px;
    height: 5px;
    transform:translate(47%, 47%);
    background-color:rgb(0, 0, 0);
    border:1px solid rgb(255, 255, 255);
    border-radius: 50%;
    z-index: 2;
}

.inner-cursor.growScanner
{
    width: 200px;
    height:200px;
    mix-blend-mode:difference;
    transition: width 0.2s cubic-bezier(.43,1.59,0,1.75), height 0.2s cubic-bezier(.43,1.59,0,1.75);
    z-index: 2;
}

.inner-cursor.growScannerSuper
{
    width: 55vh;
    height:55vh;
    mix-blend-mode:difference;
    transition: width 0.2s cubic-bezier(.43,1.59,0,1.75), height 0.2s cubic-bezier(.43,1.59,0,1.75);
    z-index: 2;
}