.section7 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80vh;
    position: relative;
    font-family: 'aloneInSpace', sans-serif;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 3vmin;
    padding-top: 5%;
    flex-direction: column;
    /* scroll-snap-align: start; */
    overflow: hidden;
    background-color: #000;
}

.scanTextPhone {
    display: none;
}

.qrCode {
    height: 30vh;
    margin-top: 14vh;
}

.qrCode:hover {
    filter: invert(100%);
    transition: 0.2s;
}

.scanSection {
    text-align: center;
    padding-top: 1%;
    animation: ScannerSvgLoop 1.5s cubic-bezier(0.76, 0, 0.3, 1) infinite alternate;
}

.lastligne {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.copyright {
    width: 0.4em;
    margin-right: 0.1em;
}

.designBy {
    font-size: 1.3vmin;
    font-family: akkuratPro;
    text-transform: capitalize;
}

.qrCodeDivision {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scanText {
    color: black;
    font-size: 1.6vmin;
}

.qrCodeDivision:hover>.scanText {
    color: #00ccff;
}

@keyframes ScannerSvgLoop {
    from {
        transform: scale(0.95);
        opacity: 0.7;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .qrCode {
        height: 26vh;
        margin-top: 14vh;
        filter: invert(100%);
    }
    .qrCodeDivision>.scanText {
        color: #00ccff;
    }
    @keyframes ScannerSvgLoop {
        from {
            transform: scale(0.8);
            opacity: 0.7;
        }
        to {
            transform: scale(0.9);
            opacity: 1;
        }
    }
    .scanText {
        display: none;
    }
    .designByPhone {
        font-size: 1vmin;
        font-family: akkuratPro;
    }
    .scanTextPhone {
        display: block;
        color: #00ccff;
        font-size: 3.4vmin;
    }
    .designBy {
        font-size: 3vmin;
    }
    .copyright {
        transform: scale(2.3);
    }
}