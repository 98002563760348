.section6 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    position: relative;
    flex-direction: column;
    font-family: 'aloneInSpace', sans-serif;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    padding-top: 1%;
    font-size: 5vmin;
    /* scroll-snap-align: start; */
    background-color: #fff;
}

.equipeTitle {
    margin-top: 2vh;
    font-size: 2vmin;
    white-space: nowrap;
}

.teamDescription {
    font-size: 2vmin;
    font-family: akkuratPro, sans-serif;
    margin: 5vh;
    text-align: justify;
}

.ligne4 {
    text-align: right;
}

.teamDescription:first-letter {
    text-transform: uppercase;
}

.leftSection {
    display: flex;
    flex: 1;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rightSection {
    /* background-color: #2AB; */
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin-left: 15%;
    margin-top:-5%;
}

.textContainerRight {
    //padding: 0 10%;
}

.textContainerLeft {
    //padding: 0 10%;
}

.profilMain {
    display: flex;
    justify-content: space-around;
    font-size: 2vmin;
    font-family: akkuratPro;
    margin: 1vh;
}

.profilPic {
    height: 15vh;
}

.alainSection,
.lorenzoSection,
.yanneSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom:1vh;
    margin-left:3vw;
    margin-right:3vw;
}

.lineText {
    display: flex;
    flex-direction: column;
}

.name:first-letter {
    text-transform: uppercase;
}

.status:first-letter {
    text-transform: uppercase;
}

.poste {
    text-transform: uppercase;
    font-size: 2vmin;
    font-family: montserrat, sans-serif;
    font-weight: 700;
}

.slogan {
    display: flex;
    justify-content: center;
    align-items: center;
}

.equipeBigTitle {
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.apostrophe {
    color: #00ccff;
    font-size: 3vmin;
    font-weight: bolder;
}

@media (max-width: 768px) {
    .profilPic {
        height: 7vh;
    }
    .lineText {
        display: flex;
        flex-direction: column;
    }
    .leftSection {
        margin-top: 0vh;
    }
    .rightSection {
        margin-top: 0vh;
        margin-bottom: 10vh;
        width:100vw;
        margin-left:0%;
    }
    .equipeTitle{
        font-size: 2.5vmin;
    }
    .equipeBigTitle {
        margin-top: 20vh;
        margin-bottom:5vh;
        font-size: 9vmin;
    }
    .teamDescription{
        font-size: 3.5vmin;
    }
    .alainSection,
    .lorenzoSection,
    .yanneSection {
        margin: 1vh 2.5vw;
    }
}