.section4 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 260vh;
    position: relative;
    font-family: 'aloneInSpace', sans-serif;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 6vmin;
    padding-top: 4%;
    padding-right: 8%;
    min-height: 260vh;
    /* scroll-snap-align: start; */
}

.imageContainer {
    height: 100vh;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
}

.productLeft {
    width: 16%;
    position: absolute;
    top: 0;
    left: 0;
}

.productTop {
    width: 16%;
    position: absolute;
    top: 0;
    left: 16%;
}

.productRight {
    width: 16%;
    position: absolute;
    top: 0;
    left: 32%;
}

.textSection {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 300vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.titleNope {
    display: none;
}

.titleText {
    color: #000;
    font-size: 5vmin;
    padding-top: 5%;
}



.featureTitle {
    color: #000;
    font-size: 2.5vmin;
    font-family: montserrat, sans-serif;
    font-weight: 600;
}

.featureDescription {
    color: #000;
    font-size: 0.4em;
    font-family: akkuratPro;
}

.featureDescription:first-letter {
    text-transform: uppercase;
}

.textSection div {
    margin-top: 30vh;
}

.descriptionComplete {
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

@media (max-width: 768px) {
    .section4 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        height: auto;
        width:100%;
        font-size: 6vmin;
        padding-top: 10%;
        min-height: 100vh;
        /* scroll-snap-align: start; */
    }
    .titleNope {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;    
        padding:10vh 0;   
    }
    .titleTextNope {
        color: #000;
        font-size: 7vmin;
        text-align: center;
        padding:2vh 0 ;
    }
    .imageContainer {
        position:relative;
        height:100vh;
        padding-left: 0;
        padding-right: 0;
        padding-top:10%;
        width: 100vw;
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:flex-start;
        /*background-color:#2AB;*/
    }
    .title {
        display: none;
    }
    .productLeft {
        width: 33.3%;
        position: absolute;
        top: 9vh;
        left: 0;
    }
    
    .productTop {
        width: 33.3%;
        position: absolute;
        top: 0;
        left: 33.3%;

    }
    
    .productRight {
        width: 33.3%;
        position: absolute;
        top: 4vh;
        left: 66.6%;
    }

    .textSection {
        
        position:relative;
        width: 100%;
        height:auto;

    }
    .textSection div {
        margin-top: 0vh;
        height:auto;
    }
    .titleText{
        display:none;
    }

    .descriptionComplete {
        padding-top:25vh;
        display: grid;
        grid-template-areas: "A B"
                             "C D";
        position:relative;
        flex-direction: row;
        width: 100%;
        height:auto;
        align-items:space-around;
        justify-content: space-around;
    }
    .reminder,    
    .cutomsShoppingList,
    .e-ticket,
    .Loyaty-Card {
        padding:3%;
        margin:5%;
        width:85%;
   }
   .featureTitle
   {
    font-size:4.5vmin;
   }
   .featureDescription
   {
    font-size: 3vmin;;
   }
}