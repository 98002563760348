.section3 {
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'aloneInSpace', sans-serif;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 8vmin;
    padding-left: 15%;
    padding-right: 10%;
    min-height: 100vh;
    /* background-color: red;  */
    /* scroll-snap-align: start; */
    overflow: hidden;
}

.videoGradient {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    transform: scaleX(1.1);
}

.blackBanner {
    position: absolute;
    top: 0;
    left: 0;
    height: 10vh;
    width: 100%;
    z-index: 1;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 15%, rgba(252, 197, 145, 0) 100%);
}

.blackBannerBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10vh;
    width: 100%;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 15%, rgba(252, 197, 145, 0) 100%);
}

.imageProduct {
    position: absolute;
    bottom: 0;
    right: 0;
}

.productMain {
    height: 95vh;
    margin-bottom: -3vh;
}

.txtProduct1 {
    font-family: montserrat;
    font-weight: bold;
}

.txtProduct1:first-letter {
    text-transform: uppercase;
}

.justLikeZatText {
    display: flex;
    flex-direction: row;
}

.justeLike {
    text-transform: uppercase;
    color: #ff1f78;
}
.imageProductHands
{
    display:none;
}

@media (max-width: 768px) {
    .section3 {
        display: flex;
        justify-content: flex-end;
        flex-direction: column-reverse;

        height: auto;
        position: relative;
        font-family: 'aloneInSpace', sans-serif;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-size: 8vmin;
        padding-left: 0%;
        padding-right: 0%;
        min-height: 100vh;
        /* scroll-snap-align: start; */
        overflow: hidden;
    }
    .productPresentation
    {
        position:relative;
        top:0vh;
        padding:4vh 2vh;
    }

    .scan,
    .pay,
    .go,
    .justLikeZatText {
        margin-top: 2vh;
    }
    .imageProduct{
        display:none;
    }
    .imageProductHands
    {
        display:block;
        position:relative;
        display:flex;
        justify-content:flex-end;
        width:100vw;
        overflow:hidden;
    }
    .productMainHands{
        height:72vh;
        margin-bottom:-1vh;
    }
}